import ExternalLinkIcon from '@components/icon/ExternalLinkIcon';
import { HomeNewsLineType } from '@models/Page';
import styled from 'styled-components';

const HomeNewsLineWrapper = styled.article`
  height: 80px;
  display: flex;
  border-bottom: 1px solid #e9e9e9;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    height: 50px;
  }
`;

const HomeNewsLineDate = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Date = styled.p`
  font-size: 20px;

  @media only screen and (max-width: 680px) {
    font-size: 15px;
  }
`;

const YearMonth = styled.p`
  font-size: 12px;
  color: #b9a7a7;

  @media only screen and (max-width: 680px) {
    font-size: 10px;
  }
`;

const HomeNewsLineTextWrapper = styled.div`
  display: flex;
  width: 220px;
  height: 79px;
  padding: 0 5px;
  align-items: center;

  @media only screen and (max-width: 680px) {
    width: calc(100% - 50px);
    max-width: 680px;
    height: 50px;
  }
`;

const HomeNewsLineText = styled.a`
  font-size: 14px;
  width: 220px;
  font-weight: 500;
  cursor: pointer;

  @media only screen and (max-width: 680px) {
    width: 100%;
    font-size: 13px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

interface HomeNewsLineProps {
  homeNewsLineConfig: HomeNewsLineType;
}

const HomeNewsLine = (props: HomeNewsLineProps) => {
  const { homeNewsLineConfig } = props;

  return (
    <HomeNewsLineWrapper>
      <HomeNewsLineDate>
        <Date>{homeNewsLineConfig.dd}</Date>
        <YearMonth>{homeNewsLineConfig.yyyymm}</YearMonth>
      </HomeNewsLineDate>
      <HomeNewsLineTextWrapper>
        <HomeNewsLineText rel="noreferrer noopener" href={homeNewsLineConfig.link} target="_blank" aria-label="Press">
          {homeNewsLineConfig.title} <ExternalLinkIcon size={15} />
        </HomeNewsLineText>
      </HomeNewsLineTextWrapper>
    </HomeNewsLineWrapper>
  );
};

export default HomeNewsLine;
