import HomeThree from './HomeThree';
import { homeThreeItem } from '@data/HomeData';
import { HomeThreeType } from '@models/Page';

const HomeThreeMap = () => {
  return (
    <>
      {homeThreeItem.map((x: HomeThreeType) => (
        <HomeThree en={x.en} ko={x.ko} key={x.ko} />
      ))}
    </>
  );
};

export default HomeThreeMap;
