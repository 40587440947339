import React from 'react';
import styled from 'styled-components';

const Strong = styled.span`
  font-weight: 500;
`;

const TitleText = styled.h2`
  color: white;
  font-size: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: 680px) {
    word-break: keep-all;
  }
`;
const SubText = styled.span`
  color: white;
  font-weight: 300;

  @media only screen and (max-width: 680px) {
    font-size: 14px;
  }
`;

const Br = styled.br`
  @media only screen and (max-width: 680px) {
    display: inline-block;
    content: ' ';
    padding: 0 2px;
  }
`;

const HomeRollingText1 = () => {
  return (
    <>
      <TitleText>임직원에게 행복을, 중소기업에게 희망을</TitleText>
      <SubText>
        <Strong>동반성장몰</Strong>은 대기업, 공공기관, 중소기업·소상공인 임직원 등이
      </SubText>
      <Br />
      <SubText>
        복지포인트 등을 활용하여{' '}
        <Strong style={{ background: 'rgb(33, 115, 227)' }}>자유롭게 온라인 구매가 가능한 상생형 플랫폼</Strong>
        입니다.
      </SubText>
    </>
  );
};

export default HomeRollingText1;
