import styled from 'styled-components';

interface HomeThreeProps {
  en: string;
  ko: string;
}

const HomeThreeItem = styled.div`
  text-align: center;
  width: 33%;
  border-right: 1px solid #e9e9e9;

  &:last-child {
    border-right: none;
  }
`;

const Title = styled.strong`
  font-size: 15px;
  font-weight: bold;

  @media only screen and (min-width: 681px) {
    font-size: 18px;
  }
`;

const Sub = styled.span`
  font-size: 13px;

  @media only screen and (min-width: 681px) {
    font-size: 16px;
  }
`;

const HomeThree = (props: HomeThreeProps) => {
  const { en, ko } = props;
  return (
    <HomeThreeItem>
      <div>
        <Title>{en}</Title>
        <br />
        <Sub>{ko}</Sub>
      </div>
    </HomeThreeItem>
  );
};

export default HomeThree;
