import HomeRollingImage from '@components/page/home/HomeRollingImage';
import Main1Mobile from '@public/assets/main1-mobile.webp';
import Main1Pc from '@public/assets/main1-pc.webp';
import Main2Mobile from '@public/assets/main2-mobile.webp';
import Main2Pc from '@public/assets/main2-pc.webp';
import React from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const HomeCarousel = () => {
  return (
    <Swiper
      spaceBetween={0}
      autoplay={false}
      effect={'fade'}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      autoHeight={true}
      touchRatio={0}
      pagination={{
        clickable: true,
        type: 'fraction',
        renderFraction: function (current, total) {
          return `<div style="display: flex;justify-content: center">
                    <div style="width: 100px;background-color: rgba(0,0,0,.4);border-radius: 10px">
                      <span style="color: white" class="${current}"></span>
                      <span style="color: #bbb"> / </span>
                      <span style="color: #bbb" class="${total}"></span>
                    </div>
                  </div>`;
        },
      }}
      modules={[EffectFade, Navigation, Pagination]}
      className="main-swiper">
      <SwiperSlide key={1}>
        <HomeRollingImage mobileImg={Main1Mobile.src} pcImg={Main1Pc.src} imgKey={1} />
      </SwiperSlide>
      <SwiperSlide key={2}>
        <HomeRollingImage mobileImg={Main2Mobile.src} pcImg={Main2Pc.src} imgKey={2} />
      </SwiperSlide>
      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
    </Swiper>
  );
};

export default HomeCarousel;
