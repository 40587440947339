import HomeBox from './HomeBox';
import { homeBoxes } from '@data/HomeData';
import { HomeBoxType } from '@models/Page';

const HomeBoxMap = () => {
  return (
    <>
      {homeBoxes.map((x: HomeBoxType) => (
        <HomeBox key={x.mainUrl} homeBoxConfig={x} />
      ))}
    </>
  );
};

export default HomeBoxMap;
