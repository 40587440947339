import HomeNewMap from './HomeNewMap';
import { useRouter } from 'next/router';
import { FaEllipsisH } from 'react-icons/fa';
import styled from 'styled-components';

const HomeNewsWrapper = styled.section`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 681px) {
    height: 390px;
    width: 300px;
  }
`;

const HomeNewsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;

  @media only screen and (max-width: 680px) {
    margin-bottom: 0;
  }
`;

const Title = styled.h4`
  font-size: 20px;

  @media only screen and (max-width: 680px) {
    font-size: 16px;
  }
`;

const BtnMore = styled.button`
  font-size: 12px;
  color: #b9a7a7;
  cursor: pointer;
  border: none;
  background: none;
`;

const HomeNews = () => {
  const router = useRouter();

  return (
    <HomeNewsWrapper>
      <HomeNewsTop>
        <Title>언론에 비친 동반성장몰</Title>
        <BtnMore onClick={() => router.push('/customer/article')}>
          더보기 <FaEllipsisH style={{ marginLeft: 3 }} />
          <div style={{ marginLeft: 5 }} />
        </BtnMore>
      </HomeNewsTop>
      <HomeNewMap />
    </HomeNewsWrapper>
  );
};

export default HomeNews;
