import HomeNewsLine from './HomeNewsLine';
import { homeNewsItem } from '@data/HomeData';
import { HomeNewsLineType } from '@models/Page';

const HomeNewMap = () => {
  return (
    <>
      {homeNewsItem.map((x: HomeNewsLineType) => (
        <HomeNewsLine key={x.key} homeNewsLineConfig={x} />
      ))}
    </>
  );
};

export default HomeNewMap;
