import HomeRollingButton from './HomeRollingButton';
import HomeRollingText1 from '@components/page/home/HomeRollingText1';
import HomeRollingText2 from '@components/page/home/HomeRollingText2';
import useIsMobile from '@hooks/useIsMobile';
import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

const RollingImageWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 680px) {
    width: auto;
    max-width: 680px;
    height: 500px;
  }
`;

const RollingImageValueWrapper = styled.section`
  width: 1100px;
  position: absolute;
  top: 25%;
  padding: 0 40px;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    padding: 0 20px;
  }
`;

interface HomeRollingImageProps {
  pcImg: string;
  mobileImg: string;
  imgKey: number;
}

const HomeRollingImage = (props: HomeRollingImageProps) => {
  const isMobile = useIsMobile();
  const { mobileImg, pcImg, imgKey } = props;
  return (
    <RollingImageWrapper>
      {isMobile ? (
        <>
          <Image src={mobileImg} alt="모바일 메인" quality={100} layout="fill" priority={imgKey === 1} />
        </>
      ) : (
        <>
          <Image src={pcImg} alt="PC 메인" quality={100} layout="fill" priority={imgKey === 1} />
        </>
      )}
      <RollingImageValueWrapper>
        {imgKey === 1 ? <HomeRollingText1 /> : <HomeRollingText2 />}
        <HomeRollingButton />
      </RollingImageValueWrapper>
    </RollingImageWrapper>
  );
};

export default HomeRollingImage;
