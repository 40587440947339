import MetaSeo from '@components/common/MetaSeo';
import HomeBoxMap from '@components/page/home/HomeBox/HomeBoxMap';
import HomeCarousel from '@components/page/home/HomeCarousel';
import HomeNews from '@components/page/home/HomeNews/HomeNews';
import HomeThreeMap from '@components/page/home/HomeThree/HomeThreeMap';
import { NextPage } from 'next';
import styled from 'styled-components';

const HomeFullWrapper = styled.section`
  width: 100%;

  @media only screen and (max-width: 680px) {
    max-width: 680px;
  }
`;

const HomeBottomFullWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;

  @media only screen and (max-width: 680px) {
    max-width: 680px;
  }
`;

const HomeBottomWrapper = styled.section`
  width: 1100px;
  display: flex;
  margin: 15px 0;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const HomeBoxWrapper = styled.section`
  display: flex;
  width: 800px;
  flex-wrap: wrap;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    padding: 0 10px;
    justify-content: space-between;
    height: 350px;
  }

  @media only screen and (min-width: 681px) {
    height: 390px;
  }
`;

const HomeThreeFullWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top: 1px solid #e9e9e9;
  height: 80px;
  width: 100%;

  @media only screen and (max-width: 680px) {
    max-width: 680px;
    height: 60px;
  }
`;

const HomeThreeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 1100px;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
  }
`;

const SITE_INFO = {
  title: '상생형 복지플랫폼',
  description:
    '동반성장몰은 대기업, 공공기관, 중소기업·소상공인 임직원 등이 자유롭게 온라인 구매가 가능한 상생형 플랫폼입니다.',
};

const Index: NextPage = () => {
  return (
    <>
      <MetaSeo title="상생형 복지플랫폼" description={SITE_INFO.description} />
      <HomeFullWrapper>
        <HomeCarousel />
        <HomeBottomFullWrapper>
          <HomeBottomWrapper>
            <HomeNews />
            <HomeBoxWrapper>
              <HomeBoxMap />
            </HomeBoxWrapper>
          </HomeBottomWrapper>
          <HomeThreeFullWrapper>
            <HomeThreeWrapper>
              <HomeThreeMap />
            </HomeThreeWrapper>
          </HomeThreeFullWrapper>
        </HomeBottomFullWrapper>
      </HomeFullWrapper>
    </>
  );
};

export default Index;
