import { HomeBoxType, HomeNewsLineType, HomeThreeType } from '../models';

export const homeBoxes: HomeBoxType[] = [
  {
    title1: '동반성장몰 소개',
    text: `도입기관 맞충형 몰구축이 가능한\n동반성장몰을 소개합니다!`,
    bg: '#5368F5',
    color: 'white',
    icon: 'assets/main1.png',
    idx: 1,
    mainUrl: '/business',
  },
  {
    title1: '동반성장몰 입점',
    title2: '희망 기업이라면?',
    text: `동반성장몰 입점을 원하시나요?\n동반성장몰과 함께 판로를 개척하세요!`,
    bg: '#323b4a',
    color: 'white',
    icon: 'assets/main4.png',
    idx: 2,
    mainUrl: '/entrance',
  },
  {
    title1: '동반성장몰 이용',
    title2: '임직원이라면',
    text: `동반성장몰 이용 중에\n궁금한 점이 있으신가요?`,
    bg: '#f6eeee',
    color: 'black',
    icon: 'assets/main3.png',
    idx: 3,
    mainUrl: '/customer',
  },
  {
    title1: '도입 희망',
    title2: '복지 담당자라면?',
    text: `동반성장몰 도입을 원하시나요?\n쉽고 빠르게 동반성장몰을 도입하세요!`,
    bg: '#e9e9e9',
    color: 'black',
    icon: 'assets/main2.png',
    idx: 4,
    mainUrl: '/introduction',
  },
];

export const homeThreeItem: HomeThreeType[] = [
  { en: 'Only For You', ko: '맞춤 상품' },
  { en: 'Everyday Sale', ko: '상시 할인' },
  { en: 'Grow With Us', ko: '상생 협력' },
];

export const homeNewsItem: HomeNewsLineType[] = [
  {
    key: '1',
    yyyymm: '23.06',
    dd: '30',
    title: "中企유통센터, 롯데마트·면세점과 \'동반성장몰 도입\' MOU",
    link: 'https://www.news1.kr/articles/5093544',
  },
  {
    key: '2',
    yyyymm: '23.04',
    dd: '03',
    title: '중기유통센터, 전남대병원 전용 동반성장몰 오픈',
    link: 'https://www.metroseoul.co.kr/article/20230403500468',
  },
  {
    key: '3',
    yyyymm: '23.02',
    dd: '02',
    title: '\'쇼핑하듯\' 튀르키예 기부…동반성장몰 구호물품관 운영',
    link: 'https://newsis.com/view/?id=NISX20230228_0002209900&cID=10433&pID=13000',
  },
  {
    key: '4',
    yyyymm: '22.06',
    dd: '09',
    title: '한국노인인력개발원, 동반성장몰 오픈…중소기업과 상생 도모',
    link: 'https://www.sedaily.com/NewsView/2676GJLTMR',
  },
];
