import { HomeBoxType } from '@models/Page';
import { useRouter } from 'next/router';
import styled from 'styled-components';

interface HomeBoxProps {
  homeBoxConfig: HomeBoxType;
}

const HomeBoxContentWrapper = styled.div`
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 680px) {
    width: calc(50% - 1px);
    padding: 15px 10px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 681px) {
    width: 395px;
    padding: 25px 20px;
    cursor: pointer;
    height: 190px;

    &:hover {
      .home-box {
        transform: scale(1.05);
      }
    }

    &:nth-child(2n-1) {
      margin-right: 10px;
    }

    &:nth-child(3) {
      margin-top: 10px;
    }

    &:nth-child(4) {
      margin-top: 10px;
    }
  }

  .home-box {
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media only screen and (max-width: 680px) {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const HomeBoxLeftWrapper = styled.div`
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const HomeBoxRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;

  @media only screen and (max-width: 680px) {
    height: 60px;
  }

  @media only screen and (min-width: 681px) {
    width: 195px;
    font-size: 14px;
  }
`;

const HomeBoxImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 680px) {
    display: none;
  }
`;

const StyledTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;

  @media only screen and (min-width: 681px) {
    font-size: 20px;
  }
`;

const DescriptionWrapper = styled.div`
  @media only screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 60px;
  }
`;

const DescriptionText = styled.span`
  @media only screen and (min-width: 681px) {
    letter-spacing: -0.7px;
  }
`;

const HomeBox = (props: HomeBoxProps) => {
  const textMapping = (text: string) => {
    return text.split('\n').map((line) => {
      return (
        <DescriptionText key={line}>
          {line}
          <br />
        </DescriptionText>
      );
    });
  };
  const { homeBoxConfig } = props;
  const router = useRouter();

  return (
    <HomeBoxContentWrapper
      style={{
        background: homeBoxConfig.bg,
      }}
      onClick={() => router.push(props.homeBoxConfig.mainUrl)}>
      <div className="home-box">
        <HomeBoxLeftWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledTitle
              style={{
                color: homeBoxConfig.color,
              }}>
              {homeBoxConfig.title1}
            </StyledTitle>
            <StyledTitle
              style={{
                color: homeBoxConfig.color,
              }}>
              {homeBoxConfig.title2}
            </StyledTitle>
          </div>
          <p style={{ fontSize: 23, color: homeBoxConfig.color }} />
        </HomeBoxLeftWrapper>
        <HomeBoxRightWrapper>
          <DescriptionWrapper style={{ color: homeBoxConfig.color }}>
            {textMapping(homeBoxConfig.text)}
          </DescriptionWrapper>
          <HomeBoxImageWrapper>
            <img src={`/${homeBoxConfig.icon}`} alt="" />
          </HomeBoxImageWrapper>
        </HomeBoxRightWrapper>
      </div>
    </HomeBoxContentWrapper>
  );
};

export default HomeBox;
