import Link from 'next/link';
import { FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';

const RollingInButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  margin-top: 20px;

  @media only screen and (max-width: 680px) {
    width: 100%;
    justify-content: center;
  }

  .search-btn {
    border: none;
    width: 130px;
    height: 35px;
    color: white;
    background: #5368f5;
    margin-left: 20px;
    border-radius: 7px;
    transition: all ease 0.3s 0s;
    font-size: 14px;

    @media only screen and (min-width: 681px) {
      width: 150px;
      height: 40px;
      margin-left: 10px;
      font-size: 16px;
    }

    &:hover {
      background: #2741f2;
    }

    &:first-child {
      margin-left: 0;
    }
    
    .text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.search-all-btn {
  background: #323b4a;

  &:hover {
    background: rgb(22 25 30);
  }
`;

const HomeRollingButton = () => {
  return (
    <RollingInButtonWrapper>
      <button className="search-btn">
        <Link href="/entrance">
          <a className="text-wrapper">
            <span>입점안내</span>
            <FaArrowRight style={{ marginLeft: 10 }} />
          </a>
        </Link>
      </button>
      <button className="search-btn search-all-btn">
        <Link href="/introduction">
          <a className="text-wrapper">
            <span>도입안내</span>
            <FaArrowRight style={{ marginLeft: 10 }} />
          </a>
        </Link>
      </button>
    </RollingInButtonWrapper>
  );
};
export default HomeRollingButton;
