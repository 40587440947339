import React from 'react';
import styled from 'styled-components';

const Strong = styled.span`
  font-weight: 500;
`;

const TitleText = styled.h2`
  color: white;
  font-size: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: 680px) {
    word-break: keep-all;
  }
`;
const SubText = styled.span`
  color: white;
  font-weight: 300;

  @media only screen and (max-width: 680px) {
    font-size: 14px;
  }
`;

const HomeRollingText2 = () => {
  return (
    <>
      <TitleText>
        <span>임직원</span>
        에게 행복을, <span>중소기업</span>
        에게 희망을
      </TitleText>
      <SubText>
        <Strong>동반성장몰</Strong>은 대기업, 공공기관, 중소기업·소상공인과 함께
      </SubText>
      <SubText>상생협력 사회를 만들어갑니다.</SubText>
    </>
  );
};

export default HomeRollingText2;
