import { WEB_CONFIG } from '@config/envConfig';
import { NextSeo } from 'next-seo';

interface MetaProps {
  title: string;
  description: string;
  keywords?: string;
  favicon?: string;
}

const isDev = WEB_CONFIG.enviornment === 'dev';

export default function MetaSeo(props: MetaProps) {
  return (
    <NextSeo
      title={props.title}
      description={props.description}
      titleTemplate={`동반성장몰${isDev ? '-DEV' : ''} - %s`}
    />
  );
}
